import React from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import TitlePage from "./title-page/titlePage";
import ArcHomePage from "./arc/homepage/homepage";
import AboutUs from "./arc/aboutus/about";
import ContactPage from "./arc/contact/contact";
import WorkPage from "./arc/work/work.jsx";
import WorkDetail from "./arc/work/work-detail.jsx";
import MenuBar from "./arc/menu/menu";

class Routes extends React.Component<any, any> {
  render() {
    const HomePageRoute = () => {
      return (
        <>
          <Switch>
            <Route exact path="/" component={TitlePage} />
          </Switch>
        </>
      );
    };

    const DefaultRoutes = () => {
      return (
        <>
          <MenuBar />

          <Switch>
            <Route path="/arc" exact={true} component={ArcHomePage} />
            <Route path="/eng" exact={true} component={ArcHomePage} />
            <Route path="/about/:page" exact={true} component={AboutUs} />
            <Route path="/work/:category" exact={true} component={WorkPage} />
            <Route
              path="/work/:category/work-detail/:id"
              exact={true}
              component={WorkDetail}
            />
            <Route path="/contact" exact={true} component={ContactPage} />
          </Switch>
        </>
      );
    };
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/" exact={true} component={HomePageRoute} />
          <Route path="/:page" component={DefaultRoutes} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default Routes;
