import React, { Fragment } from "react";
import "./team.scss";
import teamimage from "../../../../assets/members/c1.jpg";
import teamimage2 from "../../../../assets/team-min.jpg";
import { MDBIcon } from "mdbreact";

const TeamPage = (props: any) => {
  const renderInfo = () => {
    return (
      <>
        <div className="sample-img">
          <img src={teamimage2} alt="team-image" />
        </div>
      </>
    );
  };

  return <Fragment>{renderInfo()}</Fragment>;
};

export default TeamPage;
