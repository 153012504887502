import React, { Fragment, useState, useEffect } from "react";
import "./menu.scss";
import Burger from "react-css-burger";
import {
  NavLink,
  useParams,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { Transition } from "react-transition-group";
import { MDBIcon } from "mdbreact";
import ContactPage from "../contact/contact";
import AboutUs from "../aboutus/about";
import workcategories from "../work/gallerydata";

const MenuBar = (props: any) => {
  const [active, setActive] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const [activeAbout, setActiveAbout] = useState(false);
  const [activeWorks, setActiveWorks] = useState(false);
  const [showBreadcrumb, setShowBreadcrumb] = useState(false);
  let params: any = useParams();
  const mainmenu = () => {
    switch (params.page) {
      case "arc":
        return null;
      case "eng":
        return null;
      default:
        return params.page;
    }
  };
  let location: any = useLocation();
  let submenus: any = location.pathname.split("/")[2];
  const submenu = () => {
    switch (submenus) {
      case "arc":
        return null;
      case "eng":
        return null;
      case "contact":
        return null;
      default:
        return submenus;
    }
  };

  function onMenuListClick(tab: string) {
    setActive(!active);
    setActiveTab(tab);
  }

  const renderContactMenu = () => {
    return (
      <div
        className="contact-menu"
        id="sub-menu"
        style={{ fontSize: "1.9em" }}
        onClick={() => {
          setActiveWorks(!activeWorks);
          setActiveAbout(false);
        }}
      >
        CONTACT
      </div>
    );
  };

  const RenderAboutMenu = () => {
    return (
      <div className="about-menu">
        <div className="list">
          <NavLink
            exact
            to="/about/intro"
            style={{
              fontSize: "1rem",
              textDecoration: "none",
              color: "black",
              outline: "none",
              margin: "0",
            }}
            activeClassName="menu-active"
          >
            Introduction
          </NavLink>

          <NavLink
            exact
            to="/about/team"
            style={{
              fontSize: "1rem",
              textDecoration: "none",
              color: "black",
              outline: "none",
              margin: "0",
            }}
            activeClassName="menu-active"
          >
            Team
          </NavLink>
        </div>
      </div>
    );
  };

  const RenderWorkMenu = () => {
    const workcategorylist = workcategories.map((category, index) => {
      return (
        <NavLink
          to={`/work/${category.categoryname}`}
          style={{ fontSize: "1rem", textDecoration: "none", color: "black" }}
          activeClassName="menu-active"
        >
          {category.categoryname}
        </NavLink>
      );
    });
    return <div className="work-menu">{workcategorylist}</div>;
  };

  const renderTopMenuList = () => {
    return (
      <div className="menu-item">
        <a
          id="main-menu"
          onClick={() => {
            setActiveAbout(!activeAbout);
            setActiveWorks(false);
          }}
          style={{
            textDecoration: "none",
            color: "black",
            outline: "none",
          }}
        >
          {activeAbout ? (
            <span id="plus-minus"> - </span>
          ) : (
            <span id="plus-minus"> + </span>
          )}
          ABOUT
        </a>
        {activeAbout ? (
          <div id="transit">
            <RenderAboutMenu />
          </div>
        ) : (
          <div id="transit"></div>
        )}

        <a
          id="main-menu"
          onClick={() => {
            setActiveWorks(!activeWorks);
            setActiveAbout(false);
          }}
          style={{
            textDecoration: "none",
            color: "black",
            outline: "none",
            margin: "0px",
            paddingBottom: "5px",
          }}
        >
          {activeWorks ? (
            <span id="plus-minus"> - </span>
          ) : (
            <span id="plus-minus"> + </span>
          )}
          WORKS <br />
        </a>
        {activeWorks ? <RenderWorkMenu /> : ""}
        <NavLink
          id="main-menu"
          exact
          to="/contact"
          style={{
            textDecoration: "none",
            color: "black",
            paddingBottom: 0,
            outline: "none",
            paddingTop: "5px",
          }}
          onClick={() => {
            // setActiveWorks(!activeWorks);
            setActiveAbout(false);
          }}
        >
          CONTACT
        </NavLink>
      </div>
    );
  };

  const renderSwitch = (activeTab: string) => {
    switch (activeTab) {
      case "":
        return active ? renderTopMenuList() : null;
      default:
        return "No match";
    }
  };

  return (
    <Fragment>
      <div className="menu-main">
        <div className="menu">
          <div className="ham-menu">
            <Burger
              onClick={() => {
                setActive(!active);
                setActiveTab("");
                setShowBreadcrumb(!showBreadcrumb);
              }}
              active={active}
              style={{ outline: "none" }}
            />
          </div>
          {showBreadcrumb ? (
            renderSwitch(activeTab)
          ) : (
            <>
              <span className="location">{mainmenu()}</span>
              <span className="submenu">{submenu()}</span>
            </>
          )}
          <div className="copyright" title="2020 All rights reserved. ARC">
            &#169;
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MenuBar;
