import React, { Fragment } from "react";
import "./contact.scss";
import Layout from "../../common/layout";
import Footer from "../../footer/footer";
import { MDBInput, MDBBtn, MDBIcon } from "mdbreact";

const ContactPage = () => {
  return (
    <Fragment>
      <Layout />
      <div className="contact-main">
        <div className="left-page ">
          <div className="contact-form">
            <form role="form">
              <MDBInput hint="Full Name *" size="sm" required />
              <MDBInput hint="Email *" size="sm" required />
              <MDBInput hint="Contact Number *" size="sm" required />
              <MDBInput
                type="textarea"
                rows="3"
                size="sm"
                hint="Message *"
                required
              />
              <MDBBtn className="button-color">Send</MDBBtn>
            </form>
          </div>
          <div className="contact-text">
            <div style={{ fontWeight: "bold" }}>ARC</div>
            <div style={{ fontWeight: "bold" }}>
              Architects and Engineers Pvt. Ltd
            </div>
            <div>CHAKUPAT-1, Lalitpur, Nepal</div>
            <div>01-5260460)</div>
            <div>info@arc-designs.com</div>
            <div>
              <span id="icon">
                <MDBIcon fab icon="twitter" />
              </span>
              <span id="icon">
                <MDBIcon fab icon="facebook-f" />
              </span>
              <span id="icon">
                <MDBIcon fab icon="linkedin-in" />
              </span>
              <span id="icon">
                <MDBIcon fab icon="youtube" />
              </span>
              <span id="icon">
                <MDBIcon fab icon="instagram" />
              </span>
            </div>
          </div>
        </div>
        <div className="right-page">
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d113032.64621395394!2d85.25609251320085!3d27.708942727046708!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb198a307baabf%3A0xb5137c1bf18db1ea!2sKathmandu%2044600!5e0!3m2!1sen!2snp!4v1595787009983!5m2!1sen!2snp"
          ></iframe>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default ContactPage;
