import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { dataLoadingHandler } from './data/data-loader';
import { dataLoaderEpic } from './data/data-loader.epic';

export function configureStore() {

    const stateObject = {
        dataModule: dataLoadingHandler,
    };

    const rootReducer = combineReducers(stateObject);
    const rootEpic = combineEpics(dataLoaderEpic, );
    const epicMiddleware = createEpicMiddleware();

    const composeEnhancers = (window as any)['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose || compose;

    const store =  createStore(
        rootReducer,
        composeEnhancers(applyMiddleware(epicMiddleware))
    );

    epicMiddleware.run(rootEpic);

    return store;
}
