import React, { Fragment } from "react";
import "./introduction.scss";
// import TeamPage from "../team/team"
// import Footer from "../../../footer/footer";

const IntroPage = (props: any) => {
  const renderInfo = () => {
    return (
      <div className="container" id="introduction">
        <div className="introduction-content">
          <div className="title">Introduction</div>
          <div className="intro-description">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, changed.Lorem Ipsum is simply dummy text of
            the printing and typesetting industry. Lorem Ipsum has been the
            industry's standard dummy text ever since the 1500s, changed.Lorem
            Ipsum is simply dummy text of the printing and typesetting industry.
            Lorem Ipsum has been the industry's standard dummy text ever since
            the 1500s, changed.Lorem Ipsum is simply dummy text of the printing
            and typesetting industry. Lorem Ipsum has been the industry's
            standard dummy text ever since the 1500s, changed. Lorem Ipsum is
            simply dummy text of the printing and typesetting industry. Lorem
            Ipsum has been the industry's standard dummy text ever since the
            1500s, changed.
          </div>
          <div className="mission">Mission & Vision</div>
          <div className="intro-description">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, changed.Lorem Ipsum is simply dummy text of
            the printing and typesetting industry. Lorem Ipsum has been the
            industry's standard
          </div>
        </div>
      </div>
    );
  };

  return <Fragment>{renderInfo()}</Fragment>;
};

export default IntroPage;
