import React, { Fragment, useState } from "react";
import Layout from "../../common/layout";
import TeamPage from "./team/team";
import IntroPage from "./intro/introduction";
import Footer from "../../footer/footer";

const AboutUs = (props: any) => {
  const [active, setActive] = useState(false);
  const [activeTab, setActiveTab] = useState("intro");

  const renderSwitch = (activeTab: string) => {
    // debugger
    switch (activeTab) {
      case "team":
        return <TeamPage />;
      case "intro":
        return <IntroPage />;
      default:
        return "";
    }
  };
  const { match, location } = props;
  // debugger
  const { menuStatus } = props;
  return (
    <Fragment>
      <Layout />
      {/* {renderAboutMenuList()} */}
      {renderSwitch(match.params.page)}
      <Footer />
    </Fragment>
  );
};

export default AboutUs;
