import React, { useState } from "react";
import "./footer.scss";

function Footer(props) {
  const [tooltip1Open, setTooltip1Open] = useState(false);
  const [tooltip2Open, setTooltip2Open] = useState(false);

  const toggle1 = () => setTooltip1Open(!tooltip1Open);
  const toggle2 = () => setTooltip2Open(!tooltip2Open);
  return (
    <div className="footer" id="TooltipExample">
      <div className="message">
        {tooltip1Open ? (
          <div className="tooltip-1">
            &#169; 2020 All rights reserved. ARC &#x24D8;
          </div>
        ) : (
          ""
        )}
        {tooltip2Open ? (
          <div className="tooltip-2">
            &#169; 2020 All rights reserved. ARC &#x24D8;
          </div>
        ) : (
          ""
        )}
      </div>
      <div
        className="cr-logo"
        onMouseEnter={toggle1}
        onMouseLeave={toggle1}
        onClick={toggle2}
      >
        &#169;
      </div>
    </div>
  );
}

export default Footer;
