import React, { useState, Fragment } from "react";
import Layout from "../../common/layout";
import Menu from "../menu/menu";
import "./homepage.scss";
import Footer from "../../footer/footer";
import ArcHomePageSlider from "../homepage/slider/image_video_slider";

// type ArcHomePageProps = {
//     company: any;
//     activeIndex: string;
// };

// type ArcHomePageState = {
//     activeIndex: string;
//     activated: string;
// }

const ArcHomePage = () => {
  // const [activeIndex, setActiveIndex] = useState(0);
  // const [activated, setActivated] = useState('');

  const image_video_data = [
    {
      src: "image/rsz_213.jpg",
      tag: "image",
      heading: "Project Name 1",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s , changed.",
    },
    {
      src: "image/rsz_213.jpg",
      tag: "image",
      heading: "Project Name 2",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s , changed.",
    },
    {
      src: "https://www.youtube.com/watch?v=UgP9Kipzrhw",
      tag: "video",
      heading: "Project Name 3",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s , changed.",
    },
    {
      src: "image/rsz_213.jpg",
      tag: "image",
      heading: "Project Name 4",
      description:
        "Lorem Ipsumm is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, changed.4",
    },
  ];

  return (
    <Fragment>
      <Layout />
      <div className="home-wrapper">
        <div className="filler"></div>
        <div className="root-content">
          <ArcHomePageSlider image_video_data={image_video_data} />
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default ArcHomePage;
