import React, { useState } from "react";
import "./titlePage.scss";
import { Link } from "react-router-dom";

function ArchitecturePage() {
  const [arcActive, setArcActive] = useState("");
  function activateArc(status: string) {
    setArcActive(status);
  }
  const [engActive, setEngActive] = useState("");
  function activateEng(status: string) {
    setEngActive(status);
  }
  return (
    <div className="title-page">
      <div className={`architecture ${arcActive}`}>
        <Link to="/arc">
          <div
            className="architecture-page"
            onMouseEnter={() => {
              activateArc("active");
            }}
            onMouseLeave={() => {
              activateArc("");
            }}
          >
            {arcActive ? (
              <div className="img-overlay">
                <img
                  src={process.env.PUBLIC_URL + "/image/arc_logo.png"}
                  alt="Website logo"
                ></img>
              </div>
            ) : (
              <text>ARCHITECTURE</text>
            )}
          </div>
        </Link>
      </div>
      <div id="divider" className="divider"></div>

      <div className={`engineering ${engActive}`}>
        <Link to="/eng">
          <div
            className="engineering-page"
            onMouseEnter={() => {
              activateEng("active");
            }}
            onMouseLeave={() => {
              activateEng("");
            }}
          >
            {engActive ? (
              <div className="img-overlay">
                <img
                  src={process.env.PUBLIC_URL + "/image/beam_logo.png"}
                  alt="Website logo"
                ></img>
              </div>
            ) : (
              <text>ENGINEERING</text>
            )}
          </div>
        </Link>
      </div>
    </div>
  );
}

export default function TitlePage() {
  return ArchitecturePage();
}
