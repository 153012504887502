import React from "react";
import "./App.css";

// import  WithSplashScreen  from "./components/hoc/withSplashScreen";
// import TitlePage from "./components/title-page/titlePage";
// import { Switch, Route } from 'react-router-dom';
import Routes from "./components/Routes";
import "bootstrap/dist/css/bootstrap.min.css";

import { RootState } from './store/root-state';
import { Dispatch } from 'redux';
import { mainDataLoad } from './store/data/data-loader';
import { Status } from './store/data/Status';
import { connect } from 'react-redux';

const mapStateToProps = (state: RootState) => {
    return { ...state.dataModule };
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        loadData: () => dispatch(mainDataLoad())
    };
};

type AppProps = {
  status: Status,
  loadData: () => void
}

class App extends React.Component<AppProps> {

  componentDidMount() {
    console.log('Mounted');
    this.props.loadData();
  }

  render() {
    return (
      <main id="content">
        <Routes />
      </main>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
