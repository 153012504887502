const workcategories = [
    
    {
        id: 1,
        categoryname: "commercial",
        categorydescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
        projects: [
            {
                id: 1,
                projectname: "Banh mi",
                projectdescription: "Banh mi",
                src: "https://drive.google.com/uc?export=view&id=1dDdgh1ZHCmoZ9WnNZcp8i5TOXB4aytyE",
                thumbnail: "https://drive.google.com/uc?export=view&id=1dDdgh1ZHCmoZ9WnNZcp8i5TOXB4aytyE",
                thumbnailWidth: 271,
                thumbnailHeight: 320,
                images: [
                    {
                      id: 1,
                      src: "https://drive.google.com/uc?export=view&id=1dDdgh1ZHCmoZ9WnNZcp8i5TOXB4aytyE",
                      thumbnail: "https://drive.google.com/uc?export=view&id=1dDdgh1ZHCmoZ9WnNZcp8i5TOXB4aytyE",
                      thumbnailWidth: 271,
                      thumbnailHeight: 320,
                      heading: "Project1",
                      caption: "Nature",
                      description:
                        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    }
                  ],
            },
            {
                id: 2,
                projectname: "Maruti Suzuki Showroom",
                projectdescription: "Maruti Suzuki Showroom",
                src: "https://drive.google.com/uc?export=view&id=1MsVKlBFm0zxV8mqvDhmPjVC7A3aZ-nVZ",
                thumbnail: "https://drive.google.com/uc?export=view&id=1MsVKlBFm0zxV8mqvDhmPjVC7A3aZ-nVZ",
                thumbnailWidth: 271,
                thumbnailHeight: 320,
                images: [
                    {
                        id: 1,
                        src: "https://drive.google.com/uc?export=view&id=1MsVKlBFm0zxV8mqvDhmPjVC7A3aZ-nVZ",
                        thumbnail: "https://drive.google.com/uc?export=view&id=1MsVKlBFm0zxV8mqvDhmPjVC7A3aZ-nVZ",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "Nature",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    },
                    {
                        id: 2,
                        src: "https://drive.google.com/uc?export=view&id=1YtmZ6-zdR2tV0rP6YDhSMmBDN93E5g3o",
                        thumbnail: "https://drive.google.com/uc?export=view&id=1YtmZ6-zdR2tV0rP6YDhSMmBDN93E5g3o",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "Nature",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    },
                ]
            },
            {
                id: 3,
                projectname: "Mr. Sudan Shakya",
                projectdescription: "Mr. Sudan Shakya",
                src: "https://drive.google.com/uc?export=view&id=1bSfcMPcV1y6L18nSBRjF1mXKI8Olmf21",
                thumbnail: "https://drive.google.com/uc?export=view&id=1bSfcMPcV1y6L18nSBRjF1mXKI8Olmf21",
                thumbnailWidth: 271,
                thumbnailHeight: 320,
                images: [
                    {
                      id: 1,
                      src: "https://drive.google.com/uc?export=view&id=1bSfcMPcV1y6L18nSBRjF1mXKI8Olmf21",
                      thumbnail: "https://drive.google.com/uc?export=view&id=1bSfcMPcV1y6L18nSBRjF1mXKI8Olmf21",
                      thumbnailWidth: 271,
                      thumbnailHeight: 320,
                      heading: "Project1",
                      caption: "Nature",
                      description:
                        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    },
                    {
                      id: 2,
                      src: "https://drive.google.com/uc?export=view&id=1CVdtbJ6uE_98cJf4s2DKiNFr0E8_bHJG",
                      thumbnail: "https://drive.google.com/uc?export=view&id=1CVdtbJ6uE_98cJf4s2DKiNFr0E8_bHJG",
                      thumbnailWidth: 271,
                      thumbnailHeight: 320,
                      caption: "gratisography.com",
                      description:
                        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry gratisography",
                    }
                ]
            },
            {
                id: 4,
                projectname: "Suzuki Workshop",
                projectdescription: "Suzuki Workshop",
                src: "https://drive.google.com/uc?export=view&id=1u4uCY0kiEmx85fTAUN4-yNxMXB0rKXSD",
                thumbnail: "https://drive.google.com/uc?export=view&id=1u4uCY0kiEmx85fTAUN4-yNxMXB0rKXSD",
                thumbnailWidth: 271,
                thumbnailHeight: 320,
                images: [
                    {
                        id: 1,
                        src: "https://drive.google.com/uc?export=view&id=1X8XiMiCI3McbDNYkIsW_arhWP1OnJhbx",
                        thumbnail: "https://drive.google.com/uc?export=view&id=1X8XiMiCI3McbDNYkIsW_arhWP1OnJhbx",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "Nature",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    },
                    {
                        id: 2,
                        src: "https://drive.google.com/uc?export=view&id=1Nkk6DFVi29DU_T1TmGO2xIPCv7N1Zxec",
                        thumbnail: "https://drive.google.com/uc?export=view&id=1Nkk6DFVi29DU_T1TmGO2xIPCv7N1Zxec",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "Nature",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    },
                    {
                        id: 3,
                        src: "https://drive.google.com/uc?export=view&id=1u4uCY0kiEmx85fTAUN4-yNxMXB0rKXSD",
                        thumbnail: "https://drive.google.com/uc?export=view&id=1u4uCY0kiEmx85fTAUN4-yNxMXB0rKXSD",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "Nature",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    },
                ]
            },
        ]
    },
    {
        id: 2,
        categoryname: "hotel",
        categorydescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
        projects: [
            {
                id: 1,
                projectname: "Ashok Vatika Hotel",
                projectdescription: "Ashok Vatika Hotel",
                src: "https://drive.google.com/uc?export=view&id=1Mw12wbN6wFfnDSz-3liWLyL2M3te-KwZ",
                thumbnail: "https://drive.google.com/uc?export=view&id=1Mw12wbN6wFfnDSz-3liWLyL2M3te-KwZ",
                thumbnailWidth: 271,
                thumbnailHeight: 320,
                images: [
                    {
                        id: 1,
                        src: "https://drive.google.com/uc?export=view&id=1eA5PHoWrXR_3D8BtNCbOuGGWOiWaZv9-",
                        thumbnail: "https://drive.google.com/uc?export=view&id=1eA5PHoWrXR_3D8BtNCbOuGGWOiWaZv9-",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "Nature",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    },
                    {
                        id: 2,
                        src: "https://drive.google.com/uc?export=view&id=1Mw12wbN6wFfnDSz-3liWLyL2M3te-KwZ",
                        thumbnail: "https://drive.google.com/uc?export=view&id=1Mw12wbN6wFfnDSz-3liWLyL2M3te-KwZ",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "Nature",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    }
                ]
            },
            {
                id: 2,
                projectname: "Mr. Bharat Shrestha",
                projectdescription: "Mr. Bharat Shrestha",
                src: "https://drive.google.com/uc?export=view&id=12Ed9vTED2DKaYSYDtCNRfemSGgTC-HlQ",
                thumbnail: "https://drive.google.com/uc?export=view&id=12Ed9vTED2DKaYSYDtCNRfemSGgTC-HlQ",
                thumbnailWidth: 271,
                thumbnailHeight: 320,
                images: [
                    {
                        id: 1,
                        src: "https://drive.google.com/uc?export=view&id=12Ed9vTED2DKaYSYDtCNRfemSGgTC-HlQ",
                        thumbnail: "https://drive.google.com/uc?export=view&id=12Ed9vTED2DKaYSYDtCNRfemSGgTC-HlQ",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "Nature",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    },
                    {
                        id: 2,
                        src: "https://drive.google.com/uc?export=view&id=1U-5uIaPpDSI82YJD9XUBxWSKQ_xpmAxo",
                        thumbnail: "https://drive.google.com/uc?export=view&id=1U-5uIaPpDSI82YJD9XUBxWSKQ_xpmAxo",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "Nature",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    },
                    {
                        id: 3,
                        src: "https://drive.google.com/uc?export=view&id=1I9awfitcPNnSQkOWAgD3mj-OoAMzZCri",
                        thumbnail: "https://drive.google.com/uc?export=view&id=1I9awfitcPNnSQkOWAgD3mj-OoAMzZCri",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "Nature",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    },
                    {
                        id: 4,
                        src: "https://drive.google.com/uc?export=view&id=1oWukRwEFMrHnBCg5MKoEZmimL2Ix_Fm4",
                        thumbnail: "https://drive.google.com/uc?export=view&id=1oWukRwEFMrHnBCg5MKoEZmimL2Ix_Fm4",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "Nature",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    },
                ]
            },
            {
                id: 3,
                projectname: "RBB Resort",
                projectdescription: "RBB Resort",
                src: "https://drive.google.com/uc?export=view&id=1fJfyjJmLkQmvMRE9W4MOdd4S4wmyS6ad",
                thumbnail: "https://drive.google.com/uc?export=view&id=1fJfyjJmLkQmvMRE9W4MOdd4S4wmyS6ad",
                thumbnailWidth: 271,
                thumbnailHeight: 320,
                images: [
                    {
                        id: 1,
                        src: "https://drive.google.com/uc?export=view&id=1fJfyjJmLkQmvMRE9W4MOdd4S4wmyS6ad",
                        thumbnail: "https://drive.google.com/uc?export=view&id=1fJfyjJmLkQmvMRE9W4MOdd4S4wmyS6ad",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "Nature",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    },
                    {
                        id: 2,
                        src: "https://drive.google.com/uc?export=view&id=1f26PSC_ke7LJONpNZvEbxkgv3CVP4Sjq",
                        thumbnail: "https://drive.google.com/uc?export=view&id=1f26PSC_ke7LJONpNZvEbxkgv3CVP4Sjq",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "Nature",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    },
                    {
                        id: 3,
                        src: "https://drive.google.com/uc?export=view&id=1cIXTdCK4i54LdLDfMOBrNCZpbaYsPPjg",
                        thumbnail: "https://drive.google.com/uc?export=view&id=1cIXTdCK4i54LdLDfMOBrNCZpbaYsPPjg",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "Nature",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    },
                ]
            }
        ]
    },
    {
        id: 3,
        categoryname: "housing",
        categorydescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
        projects: [
            {
                id: 1,
                projectname: "Budhanilkantha Housing",
                projectdescription: "Budhanilkantha Housing",
                src: "https://drive.google.com/uc?export=view&id=1L3lGVU1smYGX1-OfvEHFBWObK8ZAqi7t",
                thumbnail: "https://drive.google.com/uc?export=view&id=1L3lGVU1smYGX1-OfvEHFBWObK8ZAqi7t",
                thumbnailWidth: 271,
                thumbnailHeight: 320,
                images: [
                    {
                        id: 1,
                        src: "https://drive.google.com/uc?export=view&id=1L3lGVU1smYGX1-OfvEHFBWObK8ZAqi7t",
                        thumbnail: "https://drive.google.com/uc?export=view&id=1L3lGVU1smYGX1-OfvEHFBWObK8ZAqi7t",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "Nature",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    },
                    {
                        id: 2,
                        src: "https://drive.google.com/uc?export=view&id=19MWsRkr2vXITSRKb8--xKfJg7nK6jQGY",
                        thumbnail: "https://drive.google.com/uc?export=view&id=19MWsRkr2vXITSRKb8--xKfJg7nK6jQGY",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "Nature",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    }
                ]
            },
            {
                id: 2,
                projectname: "CG Housing",
                projectdescription: "CG Housing",
                src: "https://drive.google.com/uc?export=view&id=1VPt-7H2woCkhGfPFS7cvZ2yMLQbqyk0s",
                thumbnail: "https://drive.google.com/uc?export=view&id=1VPt-7H2woCkhGfPFS7cvZ2yMLQbqyk0s",
                thumbnailWidth: 271,
                thumbnailHeight: 320,
                images: [
                    {
                        id: 1,
                        src: "https://drive.google.com/uc?export=view&id=1VPt-7H2woCkhGfPFS7cvZ2yMLQbqyk0s",
                        thumbnail: "https://drive.google.com/uc?export=view&id=1VPt-7H2woCkhGfPFS7cvZ2yMLQbqyk0s",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "Nature",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    },
                    {
                        id: 2,
                        src: "https://drive.google.com/uc?export=view&id=1MYSxPwjWLs72wNBwwvZZGsyGvfLQGbMK",
                        thumbnail: "https://drive.google.com/uc?export=view&id=1MYSxPwjWLs72wNBwwvZZGsyGvfLQGbMK",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "Nature",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    }
                ]
            },
            {
                id: 3,
                projectname: "Hepali Height Housing",
                projectdescription: "Hepali Height Housing",
                src: "https://drive.google.com/uc?export=view&id=1a0QPU7jgFvfXZNxv6STvEteCLEFETK33",
                thumbnail: "https://drive.google.com/uc?export=view&id=1a0QPU7jgFvfXZNxv6STvEteCLEFETK33",
                thumbnailWidth: 271,
                thumbnailHeight: 320,
                images: [
                    {
                        id: 1,
                        src: "https://drive.google.com/uc?export=view&id=1a0QPU7jgFvfXZNxv6STvEteCLEFETK33",
                        thumbnail: "https://drive.google.com/uc?export=view&id=1a0QPU7jgFvfXZNxv6STvEteCLEFETK33",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "Nature",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    },
                    {
                        id: 2,
                        src: "https://drive.google.com/uc?export=view&id=14SjmzZ6lJ1eiSHay3uCtm3_m7vEdyY7l",
                        thumbnail: "https://drive.google.com/uc?export=view&id=14SjmzZ6lJ1eiSHay3uCtm3_m7vEdyY7l",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "Nature",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    }
                ]
            },
            {
                id: 4,
                projectname: "Southern Green Housing",
                projectdescription: "Southern Green Housing",
                src: "https://drive.google.com/uc?export=view&id=1gyEo40Av2KWT95NBXqWWyWhjQoUK6aFd",
                thumbnail: "https://drive.google.com/uc?export=view&id=1gyEo40Av2KWT95NBXqWWyWhjQoUK6aFd",
                thumbnailWidth: 271,
                thumbnailHeight: 320,
                images: [
                    {
                        id: 1,
                        src: "https://drive.google.com/uc?export=view&id=1gyEo40Av2KWT95NBXqWWyWhjQoUK6aFd",
                        thumbnail: "https://drive.google.com/uc?export=view&id=1gyEo40Av2KWT95NBXqWWyWhjQoUK6aFd",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "Nature",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    },
                    {
                        id: 2,
                        src: "https://drive.google.com/uc?export=view&id=1E5fvLOYHK0fSpw1a1qwFT8AUv7eGiL_S",
                        thumbnail: "https://drive.google.com/uc?export=view&id=1E5fvLOYHK0fSpw1a1qwFT8AUv7eGiL_S",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "Nature",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    }
                ]
            }
        ]
    },
    {
        id: 4,
        categoryname: "landscape",
        categorydescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
        projects: [
            {
                id: 1,
                projectname: "Cityscape Landscape",
                projectdescription: "Cityscape Landscape",
                src: "https://drive.google.com/uc?export=view&id=16-c4GZSfVdNfkn2yEeraelpwy2ayIOr_",
                thumbnail: "https://drive.google.com/uc?export=view&id=16-c4GZSfVdNfkn2yEeraelpwy2ayIOr_",
                thumbnailWidth: 271,
                thumbnailHeight: 320,
                images: [
                    {
                        id: 1,
                        src: "https://drive.google.com/uc?export=view&id=16-c4GZSfVdNfkn2yEeraelpwy2ayIOr_",
                        thumbnail: "https://drive.google.com/uc?export=view&id=16-c4GZSfVdNfkn2yEeraelpwy2ayIOr_",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "Nature",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    },
                    {
                        id: 2,
                        src: "https://drive.google.com/uc?export=view&id=1l31DuA_DKQu9sqawzyecvzmYVN2TwpG4",
                        thumbnail: "https://drive.google.com/uc?export=view&id=1l31DuA_DKQu9sqawzyecvzmYVN2TwpG4",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "Nature",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    },
                    {
                        id: 3,
                        src: "https://drive.google.com/uc?export=view&id=1zsND6Aphb774QvLdO8W6aOYfvepxeFQd",
                        thumbnail: "https://drive.google.com/uc?export=view&id=1zsND6Aphb774QvLdO8W6aOYfvepxeFQd",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "Nature",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    },
                ]
            },
            {
                id: 2,
                projectname: "Waterpark",
                projectdescription: "Waterpark",
                src: "https://drive.google.com/uc?export=view&id=1RvJym0k7ssYTWZwGPvwaLvPPIKA_B1Bz",
                thumbnail: "https://drive.google.com/uc?export=view&id=1RvJym0k7ssYTWZwGPvwaLvPPIKA_B1Bz",
                thumbnailWidth: 271,
                thumbnailHeight: 320,
                images: [
                    {
                        id: 1,
                        src: "https://drive.google.com/uc?export=view&id=1YKK5mdV4s76KzwS2PaKbwD3g3H7rNWAj",
                        thumbnail: "https://drive.google.com/uc?export=view&id=1YKK5mdV4s76KzwS2PaKbwD3g3H7rNWAj",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "waterpark1",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    },
                    {
                        id: 2,
                        src: "https://drive.google.com/uc?export=view&id=1-YRCtMN1aBVnH03anh5R2obyvq_FaI_z",
                        thumbnail: "https://drive.google.com/uc?export=view&id=1-YRCtMN1aBVnH03anh5R2obyvq_FaI_z",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "waterpark2",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    },
                    {
                        id: 3,
                        src: "https://drive.google.com/uc?export=view&id=1ZJ8afC9i2YKxoautlKOlZ8ncOZaDhhps",
                        thumbnail: "https://drive.google.com/uc?export=view&id=1ZJ8afC9i2YKxoautlKOlZ8ncOZaDhhps",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "waterpark3",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    },
                    {
                        id: 4,
                        src: "https://drive.google.com/uc?export=view&id=1196iRJpGNoOr9oaTz-DJgQNPlNFgm7Bn",
                        thumbnail: "https://drive.google.com/uc?export=view&id=1196iRJpGNoOr9oaTz-DJgQNPlNFgm7Bn",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "waterpark4",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    },
                    {
                        id: 5,
                        src: "https://drive.google.com/uc?export=view&id=1vo3uFybPPhywb638I07k4k7HA6y0Pt0D",
                        thumbnail: "https://drive.google.com/uc?export=view&id=1vo3uFybPPhywb638I07k4k7HA6y0Pt0D",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "waterpark5",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    },
                    {
                        id: 6,
                        src: "https://drive.google.com/uc?export=view&id=11McT6mj7MwP1JZq9kGyogr11o67WHfkf",
                        thumbnail: "https://drive.google.com/uc?export=view&id=11McT6mj7MwP1JZq9kGyogr11o67WHfkf",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "waterpark6",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    },
                    {
                        id: 7,
                        src: "https://drive.google.com/uc?export=view&id=13rMsyi-osqDWhQ9afBH1p28fT53UwWdg",
                        thumbnail: "https://drive.google.com/uc?export=view&id=13rMsyi-osqDWhQ9afBH1p28fT53UwWdg",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "waterpark7",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    },
                    {
                        id: 8,
                        src: "https://drive.google.com/uc?export=view&id=15cRijp5jSsCdBh1pH0GpI14hOqG2GSPr",
                        thumbnail: "https://drive.google.com/uc?export=view&id=15cRijp5jSsCdBh1pH0GpI14hOqG2GSPr",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "waterpark8",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    },
                    {
                        id: 9,
                        src: "https://drive.google.com/uc?export=view&id=1RvJym0k7ssYTWZwGPvwaLvPPIKA_B1Bz",
                        thumbnail: "https://drive.google.com/uc?export=view&id=1RvJym0k7ssYTWZwGPvwaLvPPIKA_B1Bz",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "waterpark10",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    },
                    {
                        id: 10,
                        src: "https://drive.google.com/uc?export=view&id=121wGCXgIMU-EeiKJyDJgqmq4_rnq9Ci6",
                        thumbnail: "https://drive.google.com/uc?export=view&id=121wGCXgIMU-EeiKJyDJgqmq4_rnq9Ci6",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "waterpark11",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    },
                    {
                        id: 11,
                        src: "https://drive.google.com/uc?export=view&id=1rH7xq9cLxR6S1STmJUgvkUOd6t47a-qG",
                        thumbnail: "https://drive.google.com/uc?export=view&id=1rH7xq9cLxR6S1STmJUgvkUOd6t47a-qG",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "waterpark12",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    },
                    {
                        id: 12,
                        src: "https://drive.google.com/uc?export=view&id=1pruS6JQ7mlJ1qhHVkhwdTxMdZSwMkdrk",
                        thumbnail: "https://drive.google.com/uc?export=view&id=1pruS6JQ7mlJ1qhHVkhwdTxMdZSwMkdrk",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "waterpark13",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    },
                    {
                        id: 13,
                        src: "https://drive.google.com/uc?export=view&id=1zz2gb5dWH1dvvHja2Impv-_i7SDzW1b3",
                        thumbnail: "https://drive.google.com/uc?export=view&id=1zz2gb5dWH1dvvHja2Impv-_i7SDzW1b3",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "waterpark14",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    },
                    {
                        id: 14,
                        src: "https://drive.google.com/uc?export=view&id=1oZI3eSpm-RX1MfOldO9Fp-Mh-MicHuAk",
                        thumbnail: "https://drive.google.com/uc?export=view&id=1oZI3eSpm-RX1MfOldO9Fp-Mh-MicHuAk",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "waterpark15",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    },
                    {
                        id: 15,
                        src: "https://drive.google.com/uc?export=view&id=12AvGSkrmSV9PPvhmHAudtTRHSx_VNHP1",
                        thumbnail: "https://drive.google.com/uc?export=view&id=12AvGSkrmSV9PPvhmHAudtTRHSx_VNHP1",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "waterpark16",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    },
                    {
                        id: 16,
                        src: "https://drive.google.com/uc?export=view&id=106e0erRTAF9QtGGE1Zi0zAxy4uedgyz_",
                        thumbnail: "https://drive.google.com/uc?export=view&id=106e0erRTAF9QtGGE1Zi0zAxy4uedgyz_",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "waterpark17",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    },
                ]
            }
        ]
    },
    {
        id: 5,
        categoryname: "mixed",
        categorydescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
        projects: [
            {
                id: 1,
                projectname: "BMTCH",
                projectdescription: "BMTCH",
                src: "https://drive.google.com/uc?export=view&id=1nKt2FGbQ_oA00ikDstYQCqw1aFrzEVCQ",
                thumbnail: "https://drive.google.com/uc?export=view&id=1nKt2FGbQ_oA00ikDstYQCqw1aFrzEVCQ",
                thumbnailWidth: 271,
                thumbnailHeight: 320,
                images: [
                    {
                        id: 1,
                        src: "https://drive.google.com/uc?export=view&id=1nKt2FGbQ_oA00ikDstYQCqw1aFrzEVCQ",
                        thumbnail: "https://drive.google.com/uc?export=view&id=1nKt2FGbQ_oA00ikDstYQCqw1aFrzEVCQ",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "Nature",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    },
                    {
                        id: 2,
                        src: "https://drive.google.com/uc?export=view&id=1lQvwdvM8uVTlShj4Cq4k7PfeGdSFGKFf",
                        thumbnail: "https://drive.google.com/uc?export=view&id=1lQvwdvM8uVTlShj4Cq4k7PfeGdSFGKFf",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "Nature",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    },
                    {
                        id: 3,
                        src: "https://drive.google.com/uc?export=view&id=1KgEh9EOtbPucgVyMgIgy8uWWrJ5rmIyf",
                        thumbnail: "https://drive.google.com/uc?export=view&id=1KgEh9EOtbPucgVyMgIgy8uWWrJ5rmIyf",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "Nature",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    }
                ]
            }
        ]
    },
    {
        id: 6,
        categoryname: "residential",
        categorydescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
        projects: [
            {
                id: 1,
                projectname: "ARC Land",
                projectdescription: "ARC Land",
                src: "https://drive.google.com/uc?export=view&id=1LYg2lhsQstF_TqOO0-_WCItGAHDowch8",
                thumbnail: "https://drive.google.com/uc?export=view&id=1LYg2lhsQstF_TqOO0-_WCItGAHDowch8",
                thumbnailWidth: 271,
                thumbnailHeight: 320,
                images: [
                    {
                        id: 1,
                        src: "https://drive.google.com/uc?export=view&id=1LYg2lhsQstF_TqOO0-_WCItGAHDowch8",
                        thumbnail: "https://drive.google.com/uc?export=view&id=1LYg2lhsQstF_TqOO0-_WCItGAHDowch8",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "Nature",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    },
                    {
                        id: 2,
                        src: "https://drive.google.com/uc?export=view&id=1SR6_fAqcFYB8AVnLUcHzsSRoOTAmlnRn",
                        thumbnail: "https://drive.google.com/uc?export=view&id=1SR6_fAqcFYB8AVnLUcHzsSRoOTAmlnRn",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "Nature",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    }
                ]
            },
            {
                id: 2,
                projectname: "Mr. Arvind Chaudhary",
                projectdescription: "Mr. Arvind Chaudhary",
                src: "https://drive.google.com/uc?export=view&id=1YggPDrMk6ACOW_RXX2isMJDxJiWvD7eF",
                thumbnail: "https://drive.google.com/uc?export=view&id=1YggPDrMk6ACOW_RXX2isMJDxJiWvD7eF",
                thumbnailWidth: 271,
                thumbnailHeight: 320,
                images: [
                    {
                        id: 1,
                        src: "https://drive.google.com/uc?export=view&id=1YggPDrMk6ACOW_RXX2isMJDxJiWvD7eF",
                        thumbnail: "https://drive.google.com/uc?export=view&id=1YggPDrMk6ACOW_RXX2isMJDxJiWvD7eF",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "Nature",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    },
                    {
                        id: 2,
                        src: "https://drive.google.com/uc?export=view&id=1g5SIhlb9yjr711kf4GRaTTmm5FLCftp4",
                        thumbnail: "https://drive.google.com/uc?export=view&id=1g5SIhlb9yjr711kf4GRaTTmm5FLCftp4",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "Nature",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    }
                ]
            },
            {
                id: 3,
                projectname: "Mr. Bhupesh Sherchan",
                projectdescription: "Mr. Bhupesh Sherchan",
                src: "https://drive.google.com/uc?export=view&id=1ycnxCoFzUuu_4XyC0tnC1EsGokm-zR-h",
                thumbnail: "https://drive.google.com/uc?export=view&id=1ycnxCoFzUuu_4XyC0tnC1EsGokm-zR-h",
                thumbnailWidth: 271,
                thumbnailHeight: 320,
                images: [
                    {
                        id: 1,
                        src: "https://drive.google.com/uc?export=view&id=1ycnxCoFzUuu_4XyC0tnC1EsGokm-zR-h",
                        thumbnail: "https://drive.google.com/uc?export=view&id=1ycnxCoFzUuu_4XyC0tnC1EsGokm-zR-h",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "Nature",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    },
                    {
                        id: 2,
                        src: "https://drive.google.com/uc?export=view&id=1pRN9pBDDx3Tz_XTr_4HIbMnVvQMdfc4x",
                        thumbnail: "https://drive.google.com/uc?export=view&id=1pRN9pBDDx3Tz_XTr_4HIbMnVvQMdfc4x",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "Nature",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    }
                ]
            },
            {
                id: 4,
                projectname: "Mr. Tek Prasad Chaulagain",
                projectdescription: "Mr. Tek Prasad Chaulagain",
                src: "https://drive.google.com/uc?export=view&id=1Smdh2xcP1FZiNDreAUG8Bwv_2YnNqq87",
                thumbnail: "https://drive.google.com/uc?export=view&id=1Smdh2xcP1FZiNDreAUG8Bwv_2YnNqq87",
                thumbnailWidth: 271,
                thumbnailHeight: 320,
                images: [
                    {
                        id: 1,
                        src: "https://drive.google.com/uc?export=view&id=1Smdh2xcP1FZiNDreAUG8Bwv_2YnNqq87",
                        thumbnail: "https://drive.google.com/uc?export=view&id=1Smdh2xcP1FZiNDreAUG8Bwv_2YnNqq87",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "Nature",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    },
                    {
                        id: 2,
                        src: "https://drive.google.com/uc?export=view&id=1IvehsZAswsJBcrLw1CzZ4pixk-y-Z4oE",
                        thumbnail: "https://drive.google.com/uc?export=view&id=1IvehsZAswsJBcrLw1CzZ4pixk-y-Z4oE",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "Nature",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    },
                    {
                        id: 3,
                        src: "https://drive.google.com/uc?export=view&id=1hUd0jvQFBZ2pXZol8FpZhz59Gmc1lbCN",
                        thumbnail: "https://drive.google.com/uc?export=view&id=1hUd0jvQFBZ2pXZol8FpZhz59Gmc1lbCN",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "Nature",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    },
                    {
                        id: 4,
                        src: "https://drive.google.com/uc?export=view&id=1Y1HHJRB1eVBD19Rkuvnuf8AArD4XkHKQ",
                        thumbnail: "https://drive.google.com/uc?export=view&id=1Y1HHJRB1eVBD19Rkuvnuf8AArD4XkHKQ",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "Nature",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    },
                    {
                        id: 5,
                        src: "https://drive.google.com/uc?export=view&id=1BHXr3Fm7Vh2V4nwAb2VTEP1gN3aWcYrh",
                        thumbnail: "https://drive.google.com/uc?export=view&id=1BHXr3Fm7Vh2V4nwAb2VTEP1gN3aWcYrh",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "Nature",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    }
                ]
            },
            {
                id: 5,
                projectname: "Mr. Sushil Pandey",
                projectdescription: "Mr. Sushil Pandey",
                src: "https://drive.google.com/uc?export=view&id=1Qz3SNuaQAWXPlVLOQSlLbW56PjisHCWy",
                thumbnail: "https://drive.google.com/uc?export=view&id=1Qz3SNuaQAWXPlVLOQSlLbW56PjisHCWy",
                thumbnailWidth: 271,
                thumbnailHeight: 320,
                images: [
                    {
                        id: 1,
                        src: "https://drive.google.com/uc?export=view&id=1Qz3SNuaQAWXPlVLOQSlLbW56PjisHCWy",
                        thumbnail: "https://drive.google.com/uc?export=view&id=1Qz3SNuaQAWXPlVLOQSlLbW56PjisHCWy",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "Nature",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    },
                    {
                        id: 2,
                        src: "https://drive.google.com/uc?export=view&id=1BL0MHsz-YjvUaPs6Lxf26oOkbvK3EJ9l",
                        thumbnail: "https://drive.google.com/uc?export=view&id=1BL0MHsz-YjvUaPs6Lxf26oOkbvK3EJ9l",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "Nature",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    }
                ]
            },
            {
                id: 6,
                projectname: "Mrs. Srijana Chand",
                projectdescription: "Mrs. Srijana Chand",
                src: "https://drive.google.com/uc?export=view&id=1T58HAui9xhWdBhcvp4c0l2n2xGvzIg3x",
                thumbnail: "https://drive.google.com/uc?export=view&id=1T58HAui9xhWdBhcvp4c0l2n2xGvzIg3x",
                thumbnailWidth: 271,
                thumbnailHeight: 320,
                images: [
                    {
                        id: 1,
                        src: "https://drive.google.com/uc?export=view&id=1T58HAui9xhWdBhcvp4c0l2n2xGvzIg3x",
                        thumbnail: "https://drive.google.com/uc?export=view&id=1T58HAui9xhWdBhcvp4c0l2n2xGvzIg3x",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "Nature",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    },
                    {
                        id: 2,
                        src: "https://drive.google.com/uc?export=view&id=1LtbeZ5AJTbQZcFGMPCriyM_Z5b9YtWMC",
                        thumbnail: "https://drive.google.com/uc?export=view&id=1LtbeZ5AJTbQZcFGMPCriyM_Z5b9YtWMC",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "Nature",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    },
                    {
                        id: 3,
                        src: "https://drive.google.com/uc?export=view&id=1-wRXjQl4l5GGj1oo9de-yYAcBxsn_io4",
                        thumbnail: "https://drive.google.com/uc?export=view&id=1-wRXjQl4l5GGj1oo9de-yYAcBxsn_io4",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "Nature",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    },
                    {
                        id: 4,
                        src: "https://drive.google.com/uc?export=view&id=1C63NceM3FOJ5eaONcCp25rLyuk675e8n",
                        thumbnail: "https://drive.google.com/uc?export=view&id=1C63NceM3FOJ5eaONcCp25rLyuk675e8n",
                        thumbnailWidth: 271,
                        thumbnailHeight: 320,
                        heading: "Project1",
                        caption: "Nature",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry nature",
                    }
                ]
            }   
        ]
    }
]


export default workcategories;