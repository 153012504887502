import React, { Component, Fragment } from "react";
import Gallery from "react-grid-gallery";
import Layout from "../../common/layout";
import Footer from "../../footer/footer";
import "./work.scss";
import { Link } from "react-router-dom";
import ContactPage from "../contact/contact";
import WorkDetail from "./work-detail";
import { MDBIcon } from "mdbreact";

import Menu from "../menu/menu";
import workCategories from "./gallerydata";
import { match } from "assert";

export default class WorkPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      images: this.props.workcategories.slice(0, 12),
      activeIndex: 0,
      active: false,
      totalImages: [],
    };
    this.loadDownImage = this.loadDownImage.bind(this);
    this.loadUpImage = this.loadUpImage.bind(this);
    this.imageNavigate = this.imageNavigate.bind(this);
  }

  componentDidMount() {
    const projectId = this.props.match.params.id;
    const categoryparams = this.props.match.params.category;

    const category = workCategories.filter(
      (category) => category["categoryname"] === categoryparams
    );
    if (category) {
      this.setState({
        images: category[0].projects.slice(0, 12),
        totalImages: category[0].projects,
      });
    }
  }

  setCustomTags(i) {
    return i.tags.map((t) => {
      return (
        <div key={t.value} style={customTagStyle}>
          {" "}
          {t.title}{" "}
        </div>
      );
    });
  }

  styleSmall() {
    return {
      height: 142.9,
      width: 199.5,
    };
  }

  imageNavigate = (record) => {
    const categoryparams = this.props.match.params.category;
    var path = `/work/${categoryparams}/work-detail/` + (record + 1);
    this.props.history.push(path);
  };

  loadDownImage() {
    if (this.state.activeIndex >= this.state.images.length) {
      return;
    }
    let startIndex = this.state.activeIndex + 4;
    let endIndex = startIndex + 12;
    this.setState({
      images: this.state.totalImages.slice(startIndex, endIndex),
      activeIndex: this.state.activeIndex + 4,
    });
  }

  loadUpImage() {
    if (this.state.activeIndex < 4) {
      return;
    }
    let startIndex = this.state.activeIndex - 4;
    let endIndex = startIndex + 12;
    this.setState({
      images: this.state.totalImages.slice(startIndex, endIndex),
      activeIndex: this.state.activeIndex - 4,
    });
  }
  down() {
    return true;
  }

  render() {
    var images = this.state.images.map((i) => {
      i.customOverlay = (
        <div style={captionStyle}>
          <div>{i.caption}</div>
          {i.hasOwnProperty("tags") && this.setCustomTags(i)}
        </div>
      );
      return i;
    });
    const categoryparams = this.props.match.params.category;

    const category = workCategories.filter(
      (category) => category["categoryname"] === categoryparams
    );
    const projects = category[0].projects.map((project) => ({
      ...project,
      caption: project.projectname,
      customOverlay: (
        <div style={captionStyle}>
          <div>{project.projectname}</div>
          {project.hasOwnProperty("tags") && this.setCustomTags(project)}
        </div>
      ),
    }));

    return (
      <Fragment>
        <Layout />
        <div className="work-wrapper">
          <div className="back-grid"></div>
          <div className="gallery-main">
            {/* <div className="gallery-info"></div> */}
            <div className="gallery">
              <div className="gallery-info">
                <div className="work-info">
                  <h4>
                    {category[0] !== undefined && category[0].categoryname}
                  </h4>
                  <p>
                    {category[0] !== undefined &&
                      category[0].categorydescription}
                  </p>
                </div>
              </div>
              <div className="gallery-info-images">
                <Gallery
                  images={projects}
                  enableImageSelection={false}
                  backdropClosesModal={true}
                  tileViewportStyle={this.styleSmall}
                  thumbnailStyle={this.styleSmall}
                  onClickThumbnail={this.imageNavigate}
                  margin={2.5}
                />

                <div className="up-down-btn">
                  <div className="up-down">
                    <button
                      className="up"
                      onClick={this.loadUpImage}
                      disabled={this.state.activeIndex === 0}
                    >
                      <MDBIcon icon="chevron-up" />
                    </button>
                    <button
                      className="down"
                      onClick={this.loadDownImage}
                      disabled={
                        this.state.images.length <= 4
                          ? true
                          : this.state.activeIndex >= this.state.images.length
                          ? true
                          : false
                      }
                    >
                      <MDBIcon icon="chevron-down" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </Fragment>
    );
  }
}

// Demo4.propTypes = {
//     images: PropTypes.arrayOf(
//         PropTypes.shape({
//             src: PropTypes.string.isRequired,
//             thumbnail: PropTypes.string.isRequired,
//             srcset: PropTypes.array,
//             caption: PropTypes.oneOfType([
//                 PropTypes.string,
//                 PropTypes.element
//             ]),
//             thumbnailWidth: PropTypes.number.isRequired,
//             thumbnailHeight: PropTypes.number.isRequired
//         })
//     ).isRequired
// };

const captionStyle = {
  backgroundColor: "transparent",
  maxHeight: "240px",
  overflow: "hidden",
  position: "absolute",
  bottom: "50px",
  width: "100%",
  color: "white",
  padding: "2px",
  fontSize: "90%",
  textAlign: "center",
  zIndex: "9999999",
};

const customTagStyle = {
  wordWrap: "break-word",
  display: "inline-block",
  backgroundColor: "white",
  height: "auto",
  fontSize: "75%",
  fontWeight: "600",
  lineHeight: "1",
  padding: ".2em .6em .3em",
  borderRadius: ".25em",
  color: "black",
  verticalAlign: "baseline",
  margin: "2px",
};

WorkPage.defaultProps = {
  workcategories: workCategories,
};
